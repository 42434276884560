<template>
  <div>
    <!-- tab栏 -->
    <!-- <van-sticky>
      <van-tabs @click="onClickOnlyOne"
        color="#fb4d3f"
        title-active-color="#fb4d3f"
        swipeable
        swipe-threshold="4">
        <van-tab v-for="item in TitleList"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name">
        </van-tab>
      </van-tabs>
    </van-sticky> -->
    <van-sticky>
      <van-search v-model="listfrom.title"
        placeholder="请输入活动标题"
        shape="round"
        show-action
        @search="onSearch"
        @clear="onCancel">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
        <template #left-icon>
          <img src="https://jmylapi.zhsq.cloud//upload/files/2022/11/ZR2uIACzwrnj6968bb9baa4a2b20d6e27daf88a25a15.png"
            style="width:16px;height:16px;margin-right: 10px;margin-top: 5px;" />
        </template>
      </van-search>
    </van-sticky>
    <div class="information">
      <!-- 全部 -->
      <van-list v-model="loading"
        :finished="finished"
        :offset="100"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false">
        <div class="center borderClass"
          v-for="item in list"
          :key="item.index">
          <router-link :to="'/fivestar/wuXingXq/' + item.AId">

            <div class="Content">
              <div style="display: inline-block; width: 33%"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.AImgUrl"
                  style="border-radius:5px;min-height:85px;"
                  :src="item.AImgUrl"
                  alt="" />
                <img v-else
                  style="border-radius:5px;min-height:85px;"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png" />
              </div>
              <div style="display: inline-block; width: 63%"
                class="ContentB">
                <div class="centerTitle van-ellipsis--l2">
                  {{ item.Title }}
                </div>
                <span class="centertime van-multi-ellipsis--l2"
                  style="margin:5px 0px">{{ item.Outline }}</span>
              </div>

              <span class="centertime"
                style="float: right; margin-top: -15px;color:#999999;font-size:11px;">{{
              item.IssTime | timeFilter
            }}</span>
            </div>
          </router-link>
        </div>
      </van-list>

      <!-- 没有更多数据了 -->
      <!-- <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div> -->
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WxGetMyActivityPage, WeGetPBActivityPage } from "@/api/RealInfo";
export default {
  data () {
    return {
      akId: 0, //tab栏
      activeName: "",
      list: [], //资讯列表 
      listfrom: {
        page: 1,
        limit: 10,
        starKind: 4,
        title: '',
      }, //查询传参
      hidden: true, //没有更多数据了
      TitleList: [],
      loading: false,
      finished: false,
    };
  },
  filters: {
    timeFilter (value) {
      if (value != undefined) {
        // let gstime = value.replace('T', ' ')
        let gstime = value.substring(0, 10);
        return gstime;
      }
    }
  },
  methods: {
    // 活动分类
    getPBActivityList: function () {
      WeGetPBActivityPage({ PAKind: 2, }).then((res) => {
        this.TitleList = res.data.data;
        if (this.TitleList[0].Children != '0') {
          for (let i = 0; i < this.TitleList.length; i++) {
            const element = this.TitleList[i];
            if (element.Name == '支部过硬星') {
              this.TitleList = element.Children
              this.listfrom.akId = element.Children[0].AKId
              // this.listfrom.akId = element.AKId
            }
          }
        } else {

        }
        this.getList();
      })
    },
    // onSearch
    onSearch () {
      this.list = []
      this.listfrom.page = 1;
      // this.listfrom.title = val;
      this.getList();
    },
    onCancel () {
      this.list = []
      this.listfrom.page = 1;
      this.listfrom.title = '';
      this.getList();
    },
    // 下拉刷新
    onLoad () {
      this.loading = true
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // 获取分页列表
    getList () {
      // this.loading = true
      WxGetMyActivityPage(this.listfrom)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 0) {
            let rows = res.data.data; //请求返回当页的列表
            // 加载状态结束

            if (rows == null || rows.length === 0) {
              // 加载结束
              console.log("结束");
              this.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.list = this.list.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list.length >= res.data.count) {
              this.finished = true;
            }


            // this.loading = false
            // if (res.data.count == 0) {
            //   this.list = {};
            // } else {
            //   this.list = res.data.data;
            //   for (var i = 0; i < this.list.length; i++) {
            //     if (this.list[i].AImgUrl == "") {
            //       this.list[i].AImgUrl =
            //         "https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png";
            //     }
            //   }
            // }
          } else {
            this.finished = true;
          }
        })
        .catch(() => { });
    },
    topClick: function (name, title) {
      for (let index = 0; index < this.TitleList.length; index++) {
        const element = this.TitleList[index];
        if (element.Children.length != '0') {
          if (element.AKId == name) {
            this.listfrom.akId = element.Children[0].AKId
            this.activeName = element.Children[0].AKId
            this.getList()
          } else {
            this.listfrom.akId = element.AKId
            this.getList()
          }
        }

      }
    },
    // tab点击切换事件
    onClick (name, title) {
      this.list = []
      this.listfrom.akId = name
      this.listfrom.page = 1
      this.getList()
    },
    onClickOnlyOne (name, title) {
      this.list = []
      this.listfrom.akId = name
      this.listfrom.page = 1
      this.getList()
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }

    this.getList()
  },
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}
</style>